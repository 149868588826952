export let DataSourceURL = "";

if (process.env.NODE_ENV === "production") {
  //DataSourceURL = "http://chickenplanv3backend-112996749.eu-central-1.elb.amazonaws.com";

  DataSourceURL = "http://92.221.108.51:5000";
  //DataSourceURL = "http://ChickenPlanV3BackEnd.azurewebsites.net";
} else {
  DataSourceURL = "https://localhost:44344";
  //DataSourceURL = "http://92.221.108.51:5000";
  //DataSourceURL = "http://92.221.108.51";
  //DataSourceURL = "http://chickenplanv3backend-112996749.eu-central-1.elb.amazonaws.com";
}
