import Vue from "vue";
import VueRouter from "vue-router";
import { RouteConfig, Dictionary } from "vue-router/types/router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  {
    path: "/AktuellForType",
    name: "AktuellForType",
    component: () =>
      import(
        /* webpackChunkName: "AktuellForType" */ "../views/AktuellForType.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Avdeling", //:FirmaID",
    name: "Avdeling",
    component: () =>
      import(/* webpackChunkName: "Avdeling" */ "../views/Avdeling.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Avvik", //:TaskID",
    name: "Avvik",
    component: () =>
      import(/* webpackChunkName: "Avvik" */ "../views/Avvik.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/AvvikBehandling", //:AvvikID",
    name: "AvvikBehandling",
    component: () =>
      import(
        /* webpackChunkName: "AvvikBehandling" */ "../views/AvvikBehandling.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/AvvikStatus",
    name: "AvvikStatus",
    component: () =>
      import(/* webpackChunkName: "AvvikStatus" */ "../views/AvvikStatus.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Bruker", //:FirmaID",
    name: "Bruker",
    component: () =>
      import(/* webpackChunkName: "Bruker" */ "../views/Bruker.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/ChickenTilgang",
    name: "ChickenTilgang",
    component: () =>
      import(
        /* webpackChunkName: "ChickenTilgang" */ "../views/ChickenTilgang.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Dialog", //:TaskBehandlingID",
    name: "Dialog",
    component: () =>
      import(/* webpackChunkName: "Dialog" */ "../views/Dialog.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/DokumentType",
    name: "DokumentType",
    component: () =>
      import(
        /* webpackChunkName: "DokumentType" */ "../views/DokumentType.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Erfaring", //:TaskID",
    name: "Erfaring",
    component: () =>
      import(/* webpackChunkName: "Erfaring" */ "../views/Erfaring.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/ErfaringFirma", //:ErfaringID",
    name: "ErfaringFirma",
    component: () =>
      import(
        /* webpackChunkName: "ErfaringFirma" */ "../views/ErfaringFirma.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Firma",
    name: "Firma",
    component: () =>
      import(/* webpackChunkName: "Firma" */ "../views/Firma.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/FirmaRolle",
    name: "FirmaRolle",
    component: () =>
      import(/* webpackChunkName: "FirmaRolle" */ "../views/FirmaRolle.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/JobbTittel",
    name: "JobbTittel",
    component: () =>
      import(/* webpackChunkName: "JobbTittel" */ "../views/JobbTittel.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Katogori",
    name: "Katogori",
    component: () =>
      import(/* webpackChunkName: "Katogori" */ "../views/Katogori.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Mote", //:ProsjektID",
    name: "Mote",
    component: () => import(/* webpackChunkName: "Mote" */ "../views/Mote.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/PostNummer",
    name: "PostNummer",
    component: () =>
      import(/* webpackChunkName: "PostNummer" */ "../views/PostNummer.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Prosjekt", //:FirmaID",
    name: "Prosjekt",
    component: () =>
      import(/* webpackChunkName: "Prosjekt" */ "../views/Prosjekt.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/ProsjektFirma", //:FirmaID",
    name: "ProsjektFirma",
    component: () =>
      import(
        /* webpackChunkName: "ProsjektFirma" */ "../views/ProsjektFirma.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/ProsjektRolle",
    name: "ProsjektRolle",
    component: () =>
      import(
        /* webpackChunkName: "ProsjektRolle" */ "../views/ProsjektRolle.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/ProsjektStatus",
    name: "ProsjektStatus",
    component: () =>
      import(
        /* webpackChunkName: "ProsjektStatus" */ "../views/ProsjektStatus.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Region",
    name: "Region",
    component: () =>
      import(/* webpackChunkName: "Region" */ "../views/Region.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Rolle", //:BrukerID",
    name: "Rolle",
    component: () =>
      import(/* webpackChunkName: "Rolle" */ "../views/Rolle.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Sak", //:MoteID",
    name: "Sak",
    component: () => import(/* webpackChunkName: "Sak" */ "../views/Sak.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Sprint", //:ProsjektID",
    name: "Sprint",
    component: () =>
      import(/* webpackChunkName: "Sprint" */ "../views/Sprint.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/StillingsType",
    name: "StillingsType",
    component: () =>
      import(
        /* webpackChunkName: "StillingsType" */ "../views/StillingsType.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Task", //:ProsjektID",
    name: "Task",
    component: () => import(/* webpackChunkName: "Task" */ "../views/Task.vue"),
    meta: {
      breadcrumb: {
        label: "Task",
        parent: "",
      },
    },
  },
  {
    path: "/TaskTree", //:ProsjektID",
    name: "TaskTree",
    component: () =>
      import(/* webpackChunkName: "Task" */ "../views/TaskTree.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/TaskBehandling", //:SprintID",
    name: "TaskBehandling",
    component: () =>
      import(
        /* webpackChunkName: "TaskBehandling" */ "../views/TaskBehandling.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/TaskProsjektRolle", //:ProsjektRolleID",
    name: "TaskProsjektRolle",
    component: () =>
      import(
        /* webpackChunkName: "TaskProsjektRolle" */ "../views/TaskProsjektRolle.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/TaskStatus",
    name: "TaskStatus",
    component: () =>
      import(/* webpackChunkName: "TaskStatus" */ "../views/TaskStatus.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/TaskType",
    name: "TaskType",
    component: () =>
      import(/* webpackChunkName: "TaskType" */ "../views/TaskType.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/Vedlegg",
    name: "Vedlegg",
    component: () =>
      import(/* webpackChunkName: "Vedlegg" */ "../views/Vedlegg.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/VedleggDescType",
    name: "VedleggDescType",
    component: () =>
      import(
        /* webpackChunkName: "VedleggDescType" */ "../views/VedleggDescType.vue"
      ),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/VedleggMeta", //:DokumentTypeID",
    name: "VedleggMeta",
    component: () =>
      import(/* webpackChunkName: "VedleggMeta" */ "../views/VedleggMeta.vue"),
    meta: {
      breadcrumb: {
        label: "XXX",
        parent: "home",
      },
    },
  },
  {
    path: "/PartnerTree",
    name: "PartnerTree",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "PartnerTree" */ "../views/PartnerTree.vue"),
  },
  {
    path: "/AnsattTree",
    name: "AnsattTree",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AnsattTree" */ "../views/AnsattTree.vue"),
  },
  {
    path: "/ProsjektFirmaTeam", //:ProsjektID",
    name: "ProsjektFirmaTeam",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ProsjektFirmaTeam" */ "../views/ProsjektFirmaTeam.vue"
      ),
  },
  {
    path: "/Team", //:ProsjektID",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Team" */ "../views/Team.vue"),
  },
  {
    path: "/Gantt", //:ProsjektID",
    name: "Gantt",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Gantt" */ "../views/Gantt.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !(localStorage.getItem("user-token") || "")) {
    next({ name: "Login" });
  } else next();
});

export default router;
