/* XXXXeslint-disable promise/param-names */
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "../actions/auth";
//import { USER_REQUEST } from "../actions/user";
//import apiCall from "src/utils/api";
import axios from "axios";
import { DataSourceURL } from "../../global/variables";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  isAuthenticated: (state: { token: boolean }) => !!state.token,
  authStatus: (state: { status: string }) => state.status,
  authToken: (state: { token: string }) => state.token,
};

const actions = {
  [AUTH_REQUEST]: (
    { commit, dispatch }: { commit: any; dispatch: any },
    user: any
  ) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({
        method: "POST",
        url: DataSourceURL + "/auth/auth",
        data: user,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "POST",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            //const token = resp.data.token;
            const token = res.data.DataList.token;
            localStorage.setItem("user-token", token);
            localStorage.setItem("FirmaID", res.data.DataList.FirmaID);
            localStorage.setItem("UserID", res.data.DataList.UserID);
            localStorage.setItem("MasterFirmaID", res.data.DataList.FirmaID);
            // Here set the header of your ajax library to the token value.
            axios.defaults.headers.common["Authorization"] = token;
            commit(AUTH_SUCCESS, token);
            //dispatch(USER_REQUEST);
            resolve(res);
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          alert(`There was an error in reading GetAuth. See details: ${err}`);
          localStorage.removeItem("user-token");
          localStorage.removeItem("FirmaID");
          localStorage.removeItem("UserID");
          localStorage.setItem("UserName", "");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }: { commit: any }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token");
      localStorage.removeItem("FirmaID");
      delete axios.defaults.headers.common["Authorization"];
      localStorage.setItem("UserName", "");
      resolve();
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state: any) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state: any, resp: any) => {
    state.status = "success";
    state.token = resp.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state: any) => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state: any) => {
    state.token = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
