<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="GoToPage(child.to, child.parent)"
              router
              exact
              :disabled="child.disabled"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            @click="GoToPage(item.to, item.parent)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 600px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down"
          >Chicken plan - {{ this.currentProsjektName }}</span
        >
      </v-toolbar-title>
      <!--<v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Søk"
        class="hidden-sm-and-down"
      ></v-text-field>-->
      <!--<v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>-->
      <v-spacer></v-spacer>
      <span class="hidden-sm-and-down">{{ CurrentUser }}</span>
      <v-btn color="primary" rounded @click.prevent="logout"
        >Ut / Inn logging</v-btn
      >
      <v-btn color="primary" rounded @click.prevent="Version">Version</v-btn>

      <!--<v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn icon large>
        <v-avatar size="32px" item>
          <v-img
            src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
            alt="Vuetify"
          ></v-img
        ></v-avatar>
      </v-btn>-->
    </v-app-bar>
    <v-main>
      <router-view :key="$route.path" />
      <!-- <app-breadcrumbs></app-breadcrumbs> -->
    </v-main>
    <v-footer app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
import { DataSourceURL } from "./global/variables";
import { AUTH_LOGOUT } from "./store/actions/auth";
import Vue from "vue";
//import Breadcrumbs from "./components/Breadcrumbs";

export default Vue.extend({
  props: {
    source: String,
  },
  // components: {
  //   Breadcrumbs
  // },
  data: () => ({
    CurrentUser: "",
    currentProsjektID: 0,
    currentProsjektName: "",
    projectStatus: true,
    drawer: null,
    logOnStatus: "0",
    items: [
      { icon: "mdi-contacts", text: "Hjem", to: "Home" },

      // { icon: "mdi-history", text: "Frequently contacted" },
      // { icon: "mdi-content-copy", text: "Duplicates" },
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "Labels",
      //   model: true,
      //   children: [{ icon: "mdi-plus", text: "Create label" }]
      // },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Samhandling",
        model: false,
        children: [
          {
            icon: "mdi-city-variant-outline",
            text: "Firma",
            to: "Firma",
            parent: "",
            disabled: false,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Prosjketplan",
            to: "Prosjekt",
            parent: "Firma",
            disabled: false,
          },
          // { icon: "mdi-contacts", text: "Kontakter", to: "Bruker" },
          // { icon: "mdi-checkerboard-plus", text: "Resurssstyring", to: "Rolle" },
          // { icon: "mdi-text-box-multiple", text: "Aksjonslogg", to: "Aksjon" },
          // { icon: "mdi-human-male-female", text: "Møte", to: "Mote" },
          // { icon: "mdi-parachute", text: "Læring", to: "Erfaring" },
          // { icon: "mdi-thumb-down", text: "Avvik", to: "Avvik" },
          { text: "Vedlegg", to: "Vedlegg" },
          // { text: "Task/Gantt", to: "gantt" },
          // { text: "Import", to: "home" },
          // { text: "Export", to: "" },
          // { text: "Print", to: "about" },
          // { text: "Undo changes", to: "about" },
          // { text: "Other contacts", to: "about" },
        ],
      },
      // {
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   text: "Firma",
      //   model: false,
      //   children: [{ text: "Firma", to: "Firma" }]
      // },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Prosjekt details",
        model: false,
        children: [
          {
            icon: "mdi-contacts",
            text: "Firma",
            to: "ProsjektFirmaTeam",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-contacts",
            text: "Gruppe",
            to: "Team",
            parent: "Prosjekt",
            disabled: true,
          },
          //{ icon: "mdi-parachute", text: "Erfaring", to: "Erfaring", parent: "Prosjekt", disabled: true },
          {
            icon: "mdi-human-male-female",
            text: "Møte",
            to: "Mote",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Oppgave",
            to: "Task",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Oppgave tre",
            to: "TaskTree",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Gantt",
            to: "Gantt",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Sprint",
            to: "Sprint",
            parent: "Prosjekt",
            disabled: true,
          },
          {
            icon: "mdi-chart-timeline",
            text: "Prosjekt / Firma",
            to: "ProsjektFirma",
            parent: "Prosjekt",
            disabled: true,
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Instillinger",
        model: false,
        children: [
          { text: "Aktuell for", to: "AktuellForType" },
          { text: "Avvik status", to: "AvvikStatus" },
          { text: "Dokument typer", to: "DokumentType" },
          { text: "Firma roller", to: "FirmaRolle" },
          { text: "Jobb titler", to: "JobbTittel" },
          { text: "Katogorier", to: "Katogori" },
          { text: "Prosjekt roller", to: "ProsjektRolle" },
          { text: "Prosjekt status", to: "ProsjektStatus" },
          { text: "Region", to: "Region" },
          { text: "Stillingstyper", to: "StillingsType" },
          { text: "Oppgavetyper", to: "TaskType" },
          { text: "Oppgave statuser", to: "TaskStatus" },
          { text: "Postnummer", to: "PostNummer" },

          //{ text: "USERS", to: "USERS" },
          { text: "Vedlegg beskrivelse type", to: "VedleggDescType" },
        ],
      },
      // { icon: "mdi-settings", text: "Settings" },
      // { icon: "mdi-message", text: "Send feedback" },
      // { icon: "mdi-help-circle", text: "Help" },
      // { icon: "mdi-cellphone-link", text: "App downloads" },
      { icon: "mdi-handshake", text: "Partner", to: "PartnerTree", parent: "" },
      {
        icon: "mdi-account-hard-hat",
        text: "Ansatt",
        to: "AnsattTree",
        parent: "",
      },
      { icon: "mdi-keyboard", text: "Om" },
    ],
  }),
  methods: {
    Version: function () {
      this.GetVersion();
    },
    GetVersion() {
      axios({
        method: "get",
        url: DataSourceURL + "/Version/GetVersion",
        headers: {
          "Content-Type": "text/plain",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          alert(res.data.FeedbackText);
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Version/GetVersion. See details: ${err}`
          );
        });
    },

    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });
    },
    GoToPage: function (page, parent) {
      if (page != "") {
        switch (parent) {
          case "Firma":
            this.$router.push({
              name: page,
              params: { FirmaID: localStorage.getItem("FirmaID") },
            });
            break;
          case "Prosjekt":
            this.$router.push({
              name: page,
              params: { ProsjektID: this.currentProsjektID },
            });
            break;
          default:
            this.$router.push(page);
        }
      }
    },
  },
  created() {
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    this.$root.$on("logonChange", () => {
      //this.logOnStatus = (localStorage.getItem("RolleID") || "");
      this.CurrentUser = localStorage.getItem("UserName") || "";
    }),
      this.$root.$on("Prosjekt", (ProsjektID, ProsjektNavn) => {
        this.currentProsjektID = ProsjektID;
        this.currentProsjektName = ProsjektNavn;
        this.projectStatus = this.currentProsjektID > 0;

        this.items.forEach((item) => {
          if (Array.isArray(item.children)) {
            item.children.forEach((item1) => {
              if (item1.parent == "Prosjekt") {
                item1.disabled = !this.projectStatus;
              }
            });
          }
        });
      });
  },
});
</script>

<style></style>
